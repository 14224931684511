.purchasing-page {
  flex: 1 1 0;
  width: 100%;
  overflow: hidden;
}

.tab100 {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.errorList {
  flex: 1 1 0;
  overflow: auto;
}
