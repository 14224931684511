.step-footer {
  margin-left: 80px;
  margin-right: 80px;
}

.step-footer.prev-hidden div:first-child {
  visibility: hidden;
}

.step-footer.next-hidden div:last-child {
  visibility: hidden;
}
/* .step-footer {
  flex: 0.3;
} */
