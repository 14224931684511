.store-data-page {
  overflow: hidden;
  flex: 1 1 0;
  width: 100%;
}

.scroll-page {
  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: break-word;
  flex: 1 1 0;
  /* display: flex; */
  margin-top: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.inner-menu .nav-link {
  padding: inherit;
  line-height: 40px;
  
}
.inner-menu {
  flex: 0 0 auto;
}

.inner-menu.ant-menu-horizontal {
  padding: inherit;
  line-height: 40px;
  flex: 0 0 43px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin: 0 16px;
}

button.ant-pagination-item-link span.anticon.anticon-left {
  vertical-align: 0.09em;
}
button.ant-pagination-item-link span.anticon.anticon-right {
  vertical-align: 0.168em;
}

button span.anticon {
  vertical-align: 0.1em;
}

.min-h-350 {
  min-height: 350px;
}

.multiselect svg {
  vertical-align: baseline;
}

.ant-picker span {
  vertical-align: 0.125em;
}
