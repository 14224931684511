.smooth-image {
  transition: opacity 1s;
}
.image-visible {
  opacity: 1;
}
.image-hidden {
  opacity: 0;
}

.ding-item-image.image-loading {
  /* height: calc(90% - 50px); */
  min-height: 100px;
  position: relative;
}
.ding-item-image .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.no-ding-item {
  height: 100%;
  width: 100%;
}

.dragger-upload-pic {
  flex-grow: 1;
  align-items: stretch;
}
/* .dragger-upload-pic span.ant-upload {
  height: 80%;
} */
.dragger-upload-pic > span {
  flex-grow: 0.4;
}

.stretch-col {
  height: 100%;
  overflow: hidden;
}

.ding-item-image {
  /* flex-grow: 1;
  flex-shrink: 1; */
  overflow: hidden;
}
.ding-item-image .ant-image {
  height: 100%;
  width: 100%;
}

.ding-item-image img.ant-image-img {
  display: block;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  max-height: 100%;
  max-width: 100%;
  width: unset;
}
