.empty-grow-table-without-header .ant-spin-nested-loading {
  height: 100%;
}

.empty-grow-table-without-header .ant-spin-container {
  height: 100%;
}
.empty-grow-table-without-header .ant-table.ant-table-empty {
  height: 100%;
}

.empty-grow-table-without-header .ant-table.ant-table-small:not(.ant-table-empty) {
  height: unset;
}