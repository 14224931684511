.event-chooser {
  height: 100%;
}

.item-list {
  flex: 0.6;
  flex-basis: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.item-list .scroll-table {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 5px;
}

.choice-list {
  flex: 0.4;
  flex-basis: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.choice-list .tag-div {
  display: inline;
}
