.picking-record-gift-icon {
  vertical-align: 0.085em;
}

.btn_pick_dan {
  min-width: 38px;
  min-height: 38px;
}

.page-inner-margin-adjust .ant-page-header-content {
  padding-top: 0px;
}

.w100 {
  width: 100%;
}

.icon-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.icon-button:hover {
  filter: brightness(60%);
  opacity: 0.9;
}

.icon-button:focus {
  outline: 0;
}

.operation_cell {
  height: 100%;
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.operation_cell:hover {
  filter: brightness(60%);
  opacity: 0.9;
}
.operation_cell:focus {
  outline: 0;
}

.picking-page {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 880px) {
  .ant-table.ant-table-small .ant-table-tbody > tr > td.ant-table-cell,
  .ant-table.ant-table-small .ant-table-thead > tr > th.ant-table-cell {
    padding: 5px 3px 5px 3px;
  }
}

@media only screen and (max-width: 540px) {
  .ant-table.ant-table-small .ant-table-tbody > tr > td.ant-table-cell,
  .ant-table.ant-table-small .ant-table-thead > tr > th.ant-table-cell {
    padding: 3px 0px 3px 0px;
  }
}

@media only screen and (max-width: 682px) {
  .ant-radio-group-small label.ant-radio-button-wrapper {
    padding: 0 3px;
  }
}

@media only screen and (max-width: 650px) {
  button.btn.btn-link.buttonMP {
    display: none;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 817px) {
  button.btn.btn-link.p-0.buttonMP {
    padding: 1px 1px 1px 1px !important;
  }
}

.ant-table-cell
  > .ant-radio-group.ant-radio-group-outline.ant-radio-group-small {
  margin: 0px 0px 0px 1px;
}

/* @media only screen and (min-width: 800px) { */
.no_extend {
  max-height: 100%;
  overflow: hidden;
}
/* } */
.over-flow {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.recordCol {
  height: fit-content;
  /* max-height: 400px; */
  width: 100%;
}

.cardRecord {
  height: fit-content;
  /* max-height: 400px; */
  min-width: 100%;
}
.record-container {
  width: 100%;
}

.record-container,
.not-good-container {
  align-content: flex-start;
}

.page .picking-page-container {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.innergrid {
  height: 50%;
}
.number-picker {
  outline: none;
  max-width: 20px;
  border: none;
  /* border-bottom: 1px solid grey; */
  box-shadow: 0px 0px;
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.number-picker:hover,
.number-picker:active {
  background-color: inherit;
}

@media only screen and (max-width: 767px) {
  .number-list {
    max-height: 75px;
  }
  .no_extend {
    flex: 1;
  }
  .no_extend.stretch-col {
    display: flex;
    flex-direction: row !important;
  }
  .page .picking-page-container {
    display: flex;
    /* align-content: start; */
    flex-direction: column;
  }
  .innergrid {
    height: 100%;
  }
}

.record-button-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.one-hundred .col {
  margin: 0px;
  padding: 0px;
}

.one-hundred .row {
  margin: 0px;
  width: 100%;
}
/* Fix for .auto-scroll-y-complete */

.one-hundred {
  height: 100%;
}
.auto-scroll-y-complete {
  height: calc(100% - 162.5px);
}
.auto-scroll-y-complete .ant-spin-nested-loading {
  height: 100%;
}
.auto-scroll-y-complete .ant-spin-container {
  height: 100%;
}
.auto-scroll-y-complete .ant-table.ant-table-small.ant-table-fixed-header {
  height: 100%;
}
.auto-scroll-y-complete .ant-table-container {
  height: 100%;
}
.auto-scroll-y-complete .ant-table-body {
  -webkit-overflow-scrolling: touch;
}

.auto-scroll-y-complete .ant-table-cell {
  height: calc(100% - 39px);
}

.auto-scroll-y-complete .ant-empty.ant-empty-normal {
  height: 100%;
}
.auto-scroll-y-complete .ant-table-tbody {
  height: calc(100% - 39px);
}

/* Fix for .auto-scroll-y-input */

.one-hundred {
  height: 100%;
}
.auto-scroll-y-input {
  height: calc(100% - 33px);
}
.auto-scroll-y-input .ant-spin-nested-loading {
  height: 100%;
}
.auto-scroll-y-input .ant-spin-container {
  height: 100%;
}
.auto-scroll-y-input .ant-table.ant-table-small.ant-table-fixed-header {
  height: 100%;
}
.auto-scroll-y-input .ant-table-container {
  height: 100%;
}
.auto-scroll-y-input .ant-table-body {
  -webkit-overflow-scrolling: touch;
}

.auto-scroll-y-input .ant-table-cell {
  height: 100%;
}

.empty .ant-table table {
  min-height: 100%;
}

.auto-scroll-y-input .ant-empty.ant-empty-normal {
  height: 100%;
}
.auto-scroll-y-input .ant-table-body {
  max-height: 100%;
}
/* .ant-table-tbody {
  height: 100%;
} */

/* Fix for .auto-scroll-y */
.auto-scroll-y {
  height: 100%;
}
.auto-scroll-y .ant-table-wrapper {
  height: 100%;
}
.auto-scroll-y .ant-spin-nested-loading {
  height: 100%;
}
.auto-scroll-y .ant-spin-container {
  height: 100%;
}
.auto-scroll-y .ant-table.ant-table-small.ant-table-fixed-header {
  height: 100%;
}
.auto-scroll-y .ant-table-container {
  height: 100%;
}
.auto-scroll-y .ant-table-body {
  -webkit-overflow-scrolling: touch;
}

.ant-table-row-expand-icon-cell {
  max-width: 30px !important;
}
.ant-table-expand-icon-col {
  max-width: 30px !important;
}
col.ant-table-expand-icon-col {
  width: 40px;
}
.auto-scroll-y .ant-table-cell {
  height: calc(100% - 39px);
}

.auto-scroll-y .ant-empty.ant-empty-normal {
  height: 100%;
}

.auto-scroll-y .ant-table.ant-table-empty {
  height: 100%;
}

.auto-scroll-y .ant-table-body {
  max-height: calc(100% - 39px);
}

.ant-table-container .ant-table-body:nth-child(2) {
  height: calc(100% - 39px);
}

.ant-table-content table {
  height: 100%;
}
.ant-table-content {
  height: 100%;
}
.ant-table-container {
  height: 100%;
}
.ant-table.ant-table-small {
  height: 100%;
}
.ant-empty.ant-empty-normal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
