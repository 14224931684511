:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}
nav.navbar-dark .navbar-nav a.nav-link.active,
nav.navbar-dark .navbar-nav a.nav-link:hover,
nav.navbar-dark a.mynavIcon.navbar-brand:hover {
  color: #17a2b8;
}

nav.navbar {
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.collapse-nav-total.pl-lg-0 {
  padding-left: 3.9rem;
}
.my-navbar {
  flex: 0 0 auto;
}
