.event-check-box .ant-checkbox-wrapper {
  transform: translate(0, 1px);
}

.event-check-box {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.event-desc {
  margin-left: 24px;
  margin-top: 4px;
}

.event-modify-generate .ant-form-item.ant-row {
  margin: 3px 16px 0px 24px;
}
