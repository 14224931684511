.ant-steps-icon .anticon-check,
.ant-steps-icon .ant-steps-error-icon,
.ant-steps-icon .anticon-loading {
  vertical-align: 0.085em;
}
.generate-tab {
  position: relative;
  min-height: 200px;
  flex: 1;
  flex-basis: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
