.gift-tab {
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
}

.tab-tab {
  flex: 1;
  flex-basis: 0;
  position: relative;
}

.gift-tab .ant-tabs-content {
  height: 100%;
}
