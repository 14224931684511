.order-process-page {
  width: 100%;
  flex: 1 1 0;
  overflow: hidden;
}

.order-process-page .tab {
  flex: 1 1 0;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
}