.search-group-table {
  overflow: hidden;
}

.table-column {
  height: calc(100% - 60px);
}

.clickable {
  cursor: pointer;
}

/* Fix for .auto-scroll-y */
.scroll-search-table {
  height: 100%;
}
.scroll-search-table .ant-table-body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.scroll-search-table .ant-table-wrapper {
  height: calc(100% - 60px);
}
.scroll-search-table .ant-spin-nested-loading {
  height: 100%;
}
.scroll-search-table .empty .ant-spin-nested-loading {
  min-height: 100%;
}

.scroll-search-table .ant-spin-container {
  height: 100%;
}
.scroll-search-table .ant-table.ant-table-small.ant-table-fixed-header {
  height: 100%;
}
.scroll-search-table .ant-table-container {
  height: 100%;
}
.scroll-search-table .ant-table-body {
  -webkit-overflow-scrolling: touch;
}

.ant-table-row-expand-icon-cell {
  max-width: 30px !important;
}
.ant-table-expand-icon-col {
  max-width: 30px !important;
}
col.ant-table-expand-icon-col {
  width: 40px;
}
.scroll-search-table .ant-table-cell {
  height: calc(100% - 39px);
}

.scroll-search-table .ant-empty.ant-empty-normal {
  height: 100%;
}

.scroll-search-table .ant-table.ant-table-empty {
  height: 100%;
}

.scroll-search-table .ant-table-body {
  max-height: calc(100% - 39px);
}
