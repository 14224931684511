/* .homepage {
} */

.homepage-logo {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fill 0.5s ease forwards 3.5s;
  fill: transparent;
}

@media (max-width: 300px) {
  .homepage-logo svg g {
    stroke-width: 1px;
  }
}

@media (max-width: 500px) {
  .homepage-logo svg g {
    stroke-width: 2px;
  }
}

@media (max-width: 900px) {
  .homepage-logo svg g {
    stroke-width: 3px;
  }
  .homepage-logo {
    animation: fill 0.5s ease forwards 2.4s;
  }
}

.homepage-logo svg g {
  stroke-width: 4px;
}

.homepage-logo path:nth-child(1) {
  stroke-dasharray: 343.87200927734375px;
  stroke-dashoffset: 343.87200927734375px;
  animation: line-anim 2s ease forwards;
}

.homepage-logo path:nth-child(2) {
  stroke-dasharray: 547.9338989257812px;
  stroke-dashoffset: 547.9338989257812px;
  animation: line-anim 2s ease forwards 0.2s;
}

.homepage-logo path:nth-child(3) {
  stroke-dasharray: 484.37939453125px;
  stroke-dashoffset: 484.37939453125px;
  animation: line-anim 2s ease forwards 0.4s;
}

.homepage-logo path:nth-child(4) {
  stroke-dasharray: 404.45849609375px;
  stroke-dashoffset: 404.45849609375px;
  animation: line-anim 2s ease forwards 0.6s;
}

.homepage-logo path:nth-child(5) {
  stroke-dasharray: 518.97607421875px;
  stroke-dashoffset: 518.97607421875px;
  animation: line-anim 2s ease forwards 0.8s;
}

.homepage-logo path:nth-child(6) {
  stroke-dasharray: 810.9527587890625px;
  stroke-dashoffset: 810.9527587890625px;
  animation: line-anim 2s ease forwards 1s;
}

.homepage-logo path:nth-child(7) {
  stroke-dasharray: 518.97607421875px;
  stroke-dashoffset: 518.97607421875px;
  animation: line-anim 2s ease forwards 1.2s;
}

.homepage-logo path:nth-child(8) {
  stroke-dasharray: 478.46185302734375px;
  stroke-dashoffset: 478.46185302734375px;
  animation: line-anim 2s ease forwards 1.4s;
}

.homepage-logo path:nth-child(9) {
  stroke-dasharray: 309.8880310058594px;
  stroke-dashoffset: 309.8880310058594px;
  animation: line-anim 2s ease forwards 1.6s;
}

.homepage-logo path:nth-child(10) {
  stroke-dasharray: 505.15167236328125px;
  stroke-dashoffset: 505.15167236328125px;
  animation: line-anim 2s ease forwards 1.8s;
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0px;
  }
}
@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: #5caabd;
  }
}
