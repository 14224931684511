#root,
body,
html {
  height: 100%;
  /* max-height: -webkit-fill-available;
  min-height: -webkit-fill-available; */
  overflow: hidden;
}
#root {
  display: flex;
  position: relative;
  flex-direction: column;
}

@media only screen and (max-width: 700px) {
  .page {
    overflow: hidden;
    flex: 1 1 0;
    display: flex;
    padding: 5px 0px 5px 0px;
  }
}

.page {
  overflow: hidden;
  flex: 1 1 0;
  display: flex;
  margin-top: 2px;
  padding: 0px;
}

.fix-page {
  overflow: hidden;
  flex: 1;
  display: flex;
  margin-top: 0px;
  padding: 0px;
}

@media only screen and (max-width: 700px) {
  .container-xl.page {
    overflow: hidden;
    flex: 1;
    display: flex;
    padding: 5px 0px 5px 0px;
  }
}

.container-xl.page {
  overflow: hidden;
  flex: 1;
  display: flex;
  padding: 5px 0px 5px 0px;
}
